@use "sass:math";

@import "../../../../../stylesheets/modules_new/styles/components/my-account/my-profile/my-profile";

.my-profile__page {

    &_card{
        &_content{
          .content__wrapper{
              &_contact-container,&_passport-container,&_preference-container {
                  margin-bottom: math.div($base-margin, 6);
                  .contact-container,.passport-container,.preference-container{
                      &__country, &__passport-nationality, &__country-of-issue {
                          .country-selector__wrapper{
                              > select{
                                  height: 2rem;
                                  max-height: 2rem;
                                  background: $brand-color-24;
                                  color: saturate($brand-color-26, 50);
                              }
                          }
                      }
                      &__date-of-expiry{
                          input[type="text"]{
                              border-right: 0;
                              border-top-right-radius: 0;
                              border-bottom-right-radius: 0;
                              color: saturate($brand-color-26, 50);
                          }
                          .input-group-btn .btn{
                              padding: 0.5rem 1rem;
                              background: $brand-color-24;
                              max-height: 2rem;
                              border: $border-width solid $brand-color-23;
                              border-left: 0;
                              border-top-right-radius: math.div($panel-border-radius, 3);
                              border-bottom-right-radius: math.div($panel-border-radius, 3);
                              &:hover{
                                  background-color: $brand-color-2;
                              }
                          }
                      }
                      &__city,&__seat-preference,&__meal-preference{
                          &_value{
                              height: 2rem;
                              max-height: 2rem;
                              background: $brand-color-24;
                              color: saturate($brand-color-26, 50);
                          }
                      }
                      &__email{
                          &_value{
                              margin-top: 0;
                              background: $brand-color-24;
                              width: 100%;
                              padding: 0.2rem 0.5rem;
                              border: $border-width solid $brand-color-23;
                              border-radius: math.div($panel-border-radius, 3);
                              color: saturate($brand-color-26, 50);
                          }
                      }
                  }
              }
              &_save-button{
                  > button{
                      border-radius: math.div($panel-border-radius, 3);
                      padding: 0.4rem;
                      font-size: 1rem;
                  }
              }
              &_personal-details-container,&_frequent-flyer-container{
                  .personal-details-container,.frequent-flyer-container{
                      &__title,&__new-airline{
                          > select{
                              height: 2rem;
                              max-height: 2rem;
                              background: $brand-color-24;  
                              color: saturate($brand-color-26, 50);  
                          }
                      }
                      &__nationality{
                          .country-selector{
                              &__wrapper{
                                  > select{
                                      height: 2rem;
                                      max-height: 2rem;
                                      background: $brand-color-24;
                                      color: saturate($brand-color-26, 50);
                                  }
                              }
                          }
                      }
                      &__date-of-birth{
                          input[type="text"]{
                              border-right: 0;
                              border-top-right-radius: 0;
                              border-bottom-right-radius: 0;
                              color: saturate($brand-color-26, 50);
                          }
                          .input-group-btn .btn{
                              padding: 0.5rem 1rem;
                              background: $brand-color-24;
                              max-height: 2rem;
                              border: $border-width solid $brand-color-23;
                              border-left: 0;
                              border-top-right-radius: math.div($panel-border-radius, 3);
                              border-bottom-right-radius: math.div($panel-border-radius, 3);
                              &:hover{
                                  background-color: $brand-color-2;
                              }
                          }
                      }
                  }
              }
          }
  
          .nav>li>a {
              padding: 0.375rem 0.9375rem;
          }
        }
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
        border-color: $brand-color-23;
        border-radius: math.div($panel-border-radius, 3);
        height: 2rem;
        padding: 0.5rem 0.5rem;
        color: saturate($brand-color-26, 50);
  }
  
  select, textarea {
    border-color: $brand-color-23;
    border-radius: math.div($panel-border-radius, 3);
    background-color: $brand-color-24;
    padding: 0.5rem 0.5rem;
    color: saturate($brand-color-26, 50);
  }

  
}

.my-profile{
    &_wrapper{
        &_header {
            .header{
                &__title{
                    color: $brand-color-24;
                    margin-top: math.div($base-margin, 6);
                }
            }
        }
    }
}