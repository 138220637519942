@import "../../../../../../stylesheets/modules_new/styles/components/my-account/booking-details/download-document";

.download-document-container{
	&__doc-detail{
		&_content .content{
			&__download-icon{
				display: none;
			}
		}	
	}
}