@use "sass:math";

@import "../../../../../stylesheets/modules_new/styles/components/air/fare-rules/fare-rules";

.modal{
    &__content{
        margin: 0.25rem 0;
        .note-box{
            font-size: 0.82rem;
        }
    }
    &__header{
        &_info{
            background-color: $brand-color-2;
            border-top-left-radius: $panel-border-radius;
        }
        &_heading{
            font-size: 1.5rem;
            padding-left: $base-padding*0.5;
        }
    }
}

.header__info_part1 {
    color: $brand-color-24;
    margin:0 math.div($base-margin, 3);
    font-size: 1.125rem
}
.header__info_part {
    color: $brand-color-24;
}

.header__info_subpart1 span {
    margin-left: math.div($base-margin, 6);
}

.panel__heading {
    background-color: lighten($brand-color-2,60);
    border-top-left-radius: $panel-border-radius;
    padding: $base-padding*0.1 math.div($base-padding, 6) $base-padding*0.1 $base-padding*0.5;
    &_info{
        margin-top: $base-margin*0.1;
        color: $brand-color-23;
    }
    &_highlight{
        background-color: lighten($brand-color-2,50);
    }
}