@import "../../../../../stylesheets/modules_new/styles/components/air/fare-rules-container/fare-rules-container";

.fare-rule-container{
	.nav-tabs {
	    border-bottom: 0;
		li > a {
    		color: $brand-color-2;
    		background-color: lighten($brand-color-2,60);
			border-top-left-radius: $panel-border-radius;
        }
        li.active > a {
            background-color: $brand-color-2;
            border-top-left-radius: $panel-border-radius;
		}
	}
}

