@use "sass:math";

@import "../../../../../stylesheets/modules_new/styles/components/my-account/booking-details/select-remark-form";

.select-remark-form{
    border: $border-width solid $brand-color-2;
    &__details{
        &_fare-rule-table {
            .fare-rule-table{
                &__rule-details{
                    &_rule {
                        margin-bottom: math.div($base-margin, 3);
                        margin-top: math.div($base-margin, 3);
                        .rule{
                            &__sub-row, &__row{
                                &_rule-checkbox, &_rule-sub-checkbox, &_rule-sub-title{
                                    background-color: transparent;
                                    color: $brand-color-23;
                                    width: auto;
                                }
                            }
                            &__sub-row{
                                &_rule-title{
                                    color: $brand-color-2;
                                    font-size: 0.9375rem;
                                    font-weight: 600;
                                    padding-left: math.div($base-padding, 6);
                                    vertical-align: top;
                                    padding-top: $base-padding*0.1;
                                } 
                                display: flex;
                                padding-left: 1.25rem;
                            }
                            &__row{
                                display: flex;
                                padding-left: 1.25rem;
                                &_rule-sub-title{
                                    padding-left: math.div($base-padding, 6);
                                    padding-top: $base-padding*0.1;
                                }
                            }
                        }
                    }
                }
                &__rule-header{
                    &_title{
                        color: $brand-color-24;
                        margin-left: 0rem;
                        padding: math.div($base-padding, 6) 1.25rem;
                        background-color: desaturate($brand-color-2, 40);
                    }
                }
            }
        }
    }
    &__heading-container{
        &_heading{
            background-color: $brand-color-2;
            border-bottom: 0rem;
            font-size: 1.25rem;
            color: $brand-color-24;
            padding: $base-padding*0.1 1.25rem;
        }
    }

    &__footer-btn {
        .save-btn, .close-btn {
          color: $brand-color-24;
          &:hover {
                color: $brand-color-24;
            }
        }
    }
}